/* src/pages/ServicesPage.css */

.services-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    margin-top: 72px;
    color: #333;
}

.hero-section {
    text-align: center;
    padding: 60px 20px;
    background-color: #003366;
    color: #fff;
}

.hero-section h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.hero-section p {
    font-size: 1.2rem;
    margin-top: 10px;
}

.service-category {
    padding: 40px 20px;
    margin: 20px 0;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.service-category h2 {
    font-size: 1.8rem;
    color: #003366;
    margin-bottom: 10px;
}

.service-category p {
    font-size: 1rem;
    color: #555;
}

.cta-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #003366;
    color: #fff;
    margin-top: 40px;
    border-radius: 8px;
}

.cta-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.cta-section .button {
    display: inline-block;
    background-color: #ff6600;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta-section button:hover {
    background-color: #cc5200;
}
