/* src/pages/ContactPage.css */

.contact-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    margin-top: 72px;
    color: #333;
}

/* Hero Section */
.contact-hero {
    position: relative;
    text-align: center;
    padding: 60px 20px;
    color: #fff;
    background-color: #003366;
    overflow: hidden;
}

.contact-background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    object-fit: cover;
    z-index: -1;
}

.contact-hero h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.contact-hero p {
    font-size: 1.2rem;
    margin-top: 10px;
}

/* Company Info Section */
.company-info {
    text-align: center;
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.1rem;
}

.company-info h2 {
    color: #003366;
    margin-bottom: 20px;
}

/* Quick Contact Section */
.quick-contact {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px 0;
    text-align: center;
}

.quick-contact .contact-item {
    font-size: 1rem;
    color: #555;
    word-wrap: normal;
}

.quick-contact .contact-item i {
    font-size: 1.5rem;
    color: #003366;
    margin-bottom: 10px;
}

/* Contact Form Section */
.contact-form-section {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.contact-form-section h2 {
    text-align: center;
    font-size: 2rem;
    color: #003366;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-form label {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #555;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    margin-bottom: 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    outline: none;
    border-color: #003366;
}

.contact-form button {
    background-color: #003366;
    color: #fff;
    padding: 12px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #002244;
}
