.about-page {
    padding: 60px 20px;
    margin-top: 72px;
    background-color: #f9f9fb;
    overflow-y: hidden;
    position: relative;
}

.about-section {
    max-width: 800px;
    margin: 0 auto 60px;
    text-align: center;
    overflow-y: hidden;
}

.about-image {
    width: auto;
    height: auto;
    max-width: 100%;
    min-height: 100px;
    max-height: 400px;
}

.about-section h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.about-section p {
    font-size: 1.2em;
    color: #555;
    line-height: 1.6;
    max-width: 600px;
    margin: 0 auto;
}

body {
    overflow-x: hidden;
}
#vision-title-section {
    height: 100vh !important;
    width: 100% !important;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    /* background: #cfcfcf; */
}

#vision-title-section h2 {
    margin-top: 72px;
    font-size: 40vh;
    text-transform: uppercase;
    white-space: nowrap;
    transform: translateX(75%);
}

.vision-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.vision-section p {
    /* font-size: 2em; */
    /* width: 50%; */
    text-align: justify;
    /* min-width: 500px; */
}

.about-image-right {
    float: right;
    margin: 0 0 15px 15px;
    max-width: 300px; /* Adjust as needed */
  }
  


@media only screen and (max-width: 560px) {
    #vision-title-section h2 {
        font-size: 260px;
    }
    .vision-section p{
        width: 100%;
    }
    .about-image-right {
        float: none;
    }
    .vision-section p {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
  }