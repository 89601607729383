.navbar {
    background-color: #ffffff;
    padding: 1rem 0;
    box-shadow: -3px -7px 13px 0px #000;
    position: relative !important;
}

.navbar-brand {
    color: #000000 !important;
    font-weight: bold;
    display: flex;
}

.logo-name {
    margin-left: 10px;
}

.navbar-toggler {
    margin-right: 10px;
}

.navbar-nav .nav-link {
    color: #000000 !important;
    margin-right: 1rem;
    transition: color 0.3s;
    margin-left: 10px;
}

.navbar-nav .nav-link:hover {
    color: #e0e0e0 !important;
}

/* Navbar.css */
.navbar .nav-link {
    color: #000; /* default color */
    font-weight: 500;
}

.navbar .nav-link.active {
    color: #a600ff; /* Highlight color */
    font-weight: 600;
    border-bottom: 2px solid #a600ff; /* Add underline to active link */
}
.logo {
    width: auto;
    height: 35px;
    margin-left: 20px;
}

@media only screen and (max-width: 360px) {
    .logo-img {
        /* display: none; */
    }
    .logo-name {
        display: none;
    }
  }