/* General styles */
body {
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f8f9fa;
    overflow-x: hidden;
}

.main-welcom-container {
    position: relative;
}

.main-welcom-container .pin-spacer {
    mix-blend-mode: multiply;
    z-index: 3;
}

.welcom-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    background: linear-gradient(rgba(36, 36, 36, 0.501), rgba(0, 0, 0, 0.297)), url(../../../public/img/bgimg.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

#welcome-container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    /* Black background for contrast */
    /* mix-blend-mode: multiply; */
    /* color: white; */
    text-align: center;
    z-index: 2;
    overflow: hidden;
}

#welcome-container h1 {
    font-size: 5rem;
    text-transform: uppercase;
    font-family: 'Steelfish Rg', 'helvetica neue',
        helvetica, arial, sans-serif;
    color: white;
    /* -webkit-text-stroke: 2px rgb(0, 200, 255); */
    /* mix-blend-mode: multiply; */
    font-weight: 800;
    transform-style: preserve-3d;
    will-change: filter, transform;
}

#cursor {
    width: 35px;
    height: 35px;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.3);
    /* Semi-transparent white */
    border-radius: 50%;
    z-index: 1031;
    backdrop-filter: invert(1);
    /* Inverts the colors of what's behind the cursor */
    pointer-events: none;
    /* Ensures the cursor doesn't interfere with clicking */
    transform: translate(-50%, -50%);
}

.container {
    max-width: 100%;
    position: relative;
    padding: 0px !important;
    overflow-y: hidden;
    overflow-x: hidden;
    align-items: center;
}

.section {
    height: 100vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Hero Section */
.hero-section {
    /* background: linear-gradient(135deg, #4a90e2, #7bc0ff); */
    background: linear-gradient(rgba(0, 0, 0, 0.487), rgba(0, 0, 0, 0.487)), url(../../../public/img/bgimg.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
    text-align: center;
}

.hero-section h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.hero-section p {
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto 1.5rem;
}

.hero-section .btn {
    background-color: #fff;
    color: #a600ff;
    border: 1px solid #a600ff;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
    transition: background-color 0.3s ease;
}

.hero-section .btn:hover {
    background-color: #e5e5e5;
}

/* Features Section */
.features-section {
    background-color: #fff;
    color: #333;
    text-align: center;
    gap: 2rem;
    position: relative;
    padding-bottom: 5vh;
}

#home {
    position: relative;
    /* margin-top: 100%; */
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.home-wrapper {
    height: 100vh;
    /* Match the pinned element */
    overflow: hidden;
    /* Prevent overflow */
    position: relative;
}


canvas {
    max-width: 100vw;
    max-height: 100vh;
}

.txt-home {
    position: absolute;
    bottom: 0%;
    transform: translate(0%, -50%);
    right: 0;
    padding: 0px 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.features-text {
    color: #ffffff;
    background: rgba(0, 0, 0, 0.472);
    backdrop-filter: blur(4px);
    padding: 30px 20px;
    margin-bottom: 30px;
    width: 50%;
}

.features-text:nth-child(2){
    align-self: flex-end;
}

.features-section h3 {
    font-size: 1.5rem;
    font-weight: bold;
}

.features-section p {
    font-size: 1rem;
    color: #dcdcdc;
}

/* Services Section */
.services-section {
    /* background: linear-gradient(135deg, #2e2e2e, #3c3c3c); */
    background: #fff;
    overflow-x: hidden !important;
    min-height: 100vh;
    padding: 0rem !important;
}

.services-section h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    background: -webkit-linear-gradient(180deg,#313092, #e9018d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.services-section .lead {
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto 2rem;
}


.services-section  h4 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    background: -webkit-linear-gradient(180deg,#313092, #e9018d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.services-section .technologys {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    min-height: 100vh;
    padding: 5px;
    align-items: center;
    justify-content: center;
    background: white;
}

.services-section .technologys:nth-child(odd) {
    flex-direction: row-reverse;
}
.services-section .technologys:nth-child(even) {
    flex-direction: row;
    background-color: #f0f0f0;
}

.services-section .tech-detail {
    min-width: 250px;
    width: 50%;
}

.services-section .tech-detail h4 {
    font-size: 1.5rem;
}

.services-section .tech-detail p {
    text-align: justify;
}

.tech-img {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tech-img img {
    min-width: 250px;
}

@media only screen and (max-width: 560px) {
    #welcome-container h1 {
        font-size: 50px;
    }
}

@media only screen and (max-width: 710px) {
    .txt-home {
        justify-content: center;
        /* transform: translate(0%, -95%); */
    }
    .features-text {
        width: 100%;
    }
}

@media only screen and (max-width: 560px) {
    .txt-home {
        justify-content: center;
        transform: translate(0%, 0%);
    }
    .features-text {
        width: 100%;
    }
    #welcome-container h1 {
        font-size: 40px;
    }
}
