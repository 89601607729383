.blure-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
}

.blure-container::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(48, 48, 48, 0.164);
    backdrop-filter: blur(10px);
    z-index: -1; 
}

.spinner-container-main{
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.spinner-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    margin: 0px auto;
    overflow: hidden;
}

.spinner {
    --clr: #0c242e;
    --gap: 6px;
      /* gap between each circle */
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap);
   }
   
   .spinner span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: var(--clr);
    opacity: 0;
   }
   
   .spinner span:nth-child(1) {
    animation: fade 1s ease-in-out infinite;
   }
   
   .spinner span:nth-child(2) {
    animation: fade 1s ease-in-out 0.33s infinite;
   }
   
   .spinner span:nth-child(3) {
    animation: fade 1s ease-in-out 0.66s infinite;
   }
   
   @keyframes fade {
    0%, 100% {
     opacity: 1;
    }
   
    60% {
     opacity: 0;
    }
   }
   