/* src/pages/ServiceDetailPage.css */

.service-detail-page {
    padding: 20px;
    max-width: 900px;
    margin-top: 72px !important;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
}

.service-detail-header {
    text-align: center;
    padding: 20px;
}

.service-detail-header h1 {
    font-size: 2.5rem;
    color: #003366;
}

.service-detail-header p {
    font-size: 1.2rem;
    margin: 10px 0 20px;
    color: #555;
}

.service-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 8px;
    margin-top: 20px;
}

.service-description {
    font-size: 1rem;
    color: #444;
    margin-top: 30px;
}
