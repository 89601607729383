@font-face {
  font-family: audiowide;
  src: url(../public/assets/font/Audiowide-Regular.ttf);
}

@font-face {
  font-family: open-sans;
  src: url(../public/assets/font/OpenSans-VariableFont_wdth\,wght.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,h2,h3,h4,h5 {
  font-family: audiowide;
}
p{
  font-family: open-sans;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #2e2e2e;
  border-radius: 10px;
}

.scroll-top {
  background: black;
  color: white;
  padding: 15px;
  border-radius: 50%;
  mix-blend-mode: difference;
  backdrop-filter: invert(1);
  opacity: 0.8;
}

.redirection-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(120deg, #84fab0, #8fd3f4);
  text-align: center;
  animation: fadeIn 2s ease-in-out;
}

.redirection-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.redirection-button:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
