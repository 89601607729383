.footer {
    background-color: #2e2e2e;
    color: #ffffff;
    padding: 2rem;
}

.footer h5 {
    font-weight: bold;
    margin-bottom: 1rem;
}

.footer a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
}

.footer a:hover {
    color: #4a90e2;
}

.footer p {
    color: #cccccc;
    font-size: 0.9rem;
}
